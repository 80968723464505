<template>
  <div>
  <el-table-draggable handle=".scope-grab" @drop="orderChanged">
    <el-table
      size="mini"
      empty-text="Нет критериев"
      :data="scale.scopes"
    >
      <el-table-column width="30" v-if="!disabled">
        <template v-slot="scope">
          <i class="fas fa-arrows-alt-v scope-grab"></i>
        </template>
      </el-table-column>
      <el-table-column label="Общий % премии" width="120">
        <template v-slot="scope">
          <click-to-edit
            v-model="scope.row.pay"
            placeholder=""
            :disabled="disabled"
            style="width: 100%"
            digits-only
            @input="saveScope(scope.row)"
          ></click-to-edit>
        </template>
      </el-table-column>
      <el-table-column label="Описание достижения">
        <template v-slot="scope">
          <click-to-edit
            v-model="scope.row.target"
            placeholder=""
            :disabled="disabled"
            style="width: 100%"
            type="textarea"
            @input="saveScope(scope.row)"
          ></click-to-edit>
        </template>
      </el-table-column>
      <el-table-column label="Краткое описание для факта">
        <template v-slot="scope">
          <click-to-edit
            v-model="scope.row.target_short"
            placeholder=""
            :disabled="disabled"
            style="width: 100%"
            type="textarea"
            :word-limit="100"
            show-word-limit
            @input="saveScope(scope.row)"
          ></click-to-edit>
        </template>
      </el-table-column>
      <el-table-column width="45" align="right" v-if="!disabled">
        <template v-slot="scope">
          <el-button
            class="table-action-button"
            size="mini"
            round
            icon="far fa-trash-alt"
            @click="deleteScope(scope.row)"
          >
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </el-table-draggable>
    <el-button
      v-show="!disabled"
      style="margin-top: 5px"
      type="primary"
      size="mini"
      icon="fas fa-plus"
      @click="$emit('add-scope')"
    >
      Создать критерий
    </el-button>
  </div>
</template>

<script>
import ElTableDraggable from "element-ui-el-table-draggable";
import {configurableScaleScopes} from "@/mixins/configurableScaleScopes";
import ClickToEdit from "@/components/ClickToEdit.vue";

export default {
  name: "quality-scopes",
  components: {ClickToEdit, ElTableDraggable},
  mixins: [configurableScaleScopes],
  props: {},
  watch: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {

  }
}
</script>


<style lang="scss" scoped>
.scope-grab {
  padding: 0 7px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.scope-grab:active {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
</style>